import React from 'react';
import Layout from '../../components/layout/Layout';
import PageNavigator from "../../components/layout/PageNavigator";
import ContentAM from '../../components/ContentAM';
import license from '../../data/corsi-patente/patente-D/license.json';
import Seo from '../../components/layout/SEO';
import BannerCompass from '../../components/BannerCompass';

const PatentiD = () => {
  return (
    <Layout>
      <Seo
        title={"Corsi per Patente D | Autoscuola Faro, Pisa"}
        description={"Con i nostri corsi puoi prendere la patente D, D1, DE, D1E per guidare minibus fino a 8 o 16 persone a bordo. Siamo a Pisa!"}
        keywords={[
          "Corsi Patente D Pisa",
          "Corsi Patente D1 Pisa",
          "Corsi Patente DE Pisa",
          "Patente Minibus Pisa",
          "Guidare Minibus Pisa",
          "Autoscuola Faro Pisa"
        ]}
      />

      <PageNavigator link="/corsi-patente" text="Corsi patenti" />
      <ContentAM license={license} />
      <BannerCompass />
    </Layout>
  )
};
export default PatentiD;
